<script>

import PageTitle from "@/components/PageTitle.vue";
import FormRequestDetailHeader from "@/components/FormRequests/FormRequestDetailHeader.vue";
import {each} from "@/client/utilities/Each";
import {get} from "@/client/utilities/Object";
import TransmittalForm from "@/common/api/forms/TransmittalForm";
import ProjectManagerAutocomplete from "@/components/V2/ProjectManagerAutocomplete.vue";
import JobsAutocomplete from "@/components/V2/JobsAutocomplete.vue";
import {TabulatorFull} from "tabulator-tables";
import TextInput from "@/components/TextInput.vue";
import DatePicker from "@/components/V2/DatePicker.vue";
import DeliveryWindowSelect from "@/components/V2/DeliveryWindowSelect.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import FormSubmittedMessage from "@/components/FormSubmittedMessage.vue";
import cloneObject from "@/common/clone-object";
import {LOG_STYLES} from "@/common/log";
import AddLineItemDialog from "@/components/Dialogs/Transmittal/AddLineItemDialog.vue";

export default {
    name: 'TransmittalRequestForm',
    title: 'Transmittal Request',
    logContext: 'TransmittalRequestForm',
    logStyle: LOG_STYLES.TEAL,
    components: {
        AddLineItemDialog,
        FormSubmittedMessage,
        NotesTextField,
        DeliveryWindowSelect,
        DatePicker, TextInput, JobsAutocomplete, ProjectManagerAutocomplete, FormRequestDetailHeader, PageTitle
    },
    watch: {
        'form.job'(job) {

            if (job) {
                let pm_id = get(this.form, 'job.project_manager_user_id');

                if (!pm_id) {
                    pm_id = get(this.form, 'job.reviewer');
                }

                let project_manager = this.data._project_managers[pm_id];
                console.log('Project Manager:', project_manager);

                if (this.foremanName) {
                    this.form.delivery.site_contact_name = this.foremanName;
                }

                if (project_manager) {
                    this.form.project_manager = project_manager;
                } else {
                    this.form.project_manager = get(this.data._project_managers, 523);
                }

                this.updateFormRequest();
                this.fetchInventoryItems();
                this.data.jobs_for_new_job = cloneObject(this.jobsForNewJob);

            } else {
                this.form.project_manager = null;
                this.form.items = [];
            }
        },
        'form.project_manager'(project_manager) {
            if (!project_manager) {
                this.form.job = null;
            }
        },
        'form.mode'(mode) {
            this.form.delivery = {
                site_contact_name: this.foremanName,
                delivery_date: '',
                delivery_window: '',
                delivery_notes: ''
            }
        }
    },
    data() {
        return {
            addItemDialog: false,
            rules: {
                required: v => !!v || "This is a required field",
            },
            tabulator: null,
            selectableCheck: (row) => {
                return row.getData().editable;
            },
            columns: [
                {
                    title: 'Photo',
                    field: 'photo',
                    formatter: (cell, formatterParams, onRendered) => {
                        const photo = cell.getValue();

                        if (photo && photo.thumbnail) {
                            return `<a href="${photo.url}"><img src="${photo.thumbnail.url}" width="30"/></a>`;
                        }

                        return '';
                    }
                },
                {
                    title: 'Part Desc',
                    field: 'part_description',
                    editor: 'list',
                    minWidth: 400,
                    editable: this.rowIsEditable
                },
                {
                    title: 'Part #',
                    field: 'part_number',
                    editor: 'input',
                    hozAlign: 'right',
                    headerHozAlign: 'right',
                    minWidth: 150,
                    frozen: true,
                    editorParams: {
                        selectContents: true
                    },
                    editable: this.rowIsEditable
                },
                {
                    title: 'Avail',
                    field: 'part_quantity',
                    hozAlign: 'right',
                    headerHozAlign: 'right',
                    minWidth: 80,
                    width: 80,
                    maxWidth: 100,
                    frozen: true,
                },
                {
                    title: 'Qty',
                    field: 'transmittal_quantity',
                    editor: 'number',
                    editorParams: {
                        selectContents: true
                    },
                    validator: "partQuantityMax",
                    hozAlign: 'right',
                    headerHozAlign: 'right',
                    minWidth: 80,
                    width: 80,
                    maxWidth: 100,
                    frozen: true
                }
            ],
            table: {
                loading: false
            },
            step: 1,
            steps: {
                1: {
                    name: 'Select Job',
                    isValid: () => {
                        return this.form.job !== null && this.form.project_manager !== null;
                    }
                },
                2: {
                    name: 'Select Method',
                    isValid: () => {
                        let valid = true;

                        if (this.form.mode === null) {
                            valid = false;
                        }

                        if (this.form.mode === 'warehouseToJobsite') {

                            if (this.form.delivery.site_contact_name.length === 0) {
                                valid = false;
                            }

                            if (this.form.delivery.delivery_date.length === 0) {
                                valid = false;
                            }

                            if (this.form.delivery.delivery_window.length === 0) {
                                valid = false;
                            }
                        }

                        if (this.form.mode === 'switchToJob' || this.form.mode === 'switchToStock') {
                            if (this.form.newJob === null) {
                                valid = false;
                            }
                        }

                        return valid;
                    }
                },
                3: {
                    name: 'Enter Quantities',
                    isValid: () => {
                        let valid = this.stepValid(1) && this.stepValid(2);

                        let total_quantity = 0;

                        each(this.form.items, (item) => {
                            if (item) {
                                total_quantity += item.transmittal_quantity;
                            }
                        });

                        if (total_quantity <= 0) {
                            valid = false;
                        }

                        return valid;
                    }
                }
            },
            methodNames: {
                warehouseToJobsite: 'Warehouse to Jobsite',
                switchToJob: 'Switch to Job',
                switchToStock: 'Switch to Stock',
            },
            form: {
                job: null,
                newJob: null,
                project_manager: null,
                items: [],
                mode: 'warehouseToJobsite',
                delivery: {
                    site_contact_name: '',
                    delivery_date: '',
                    delivery_window: '',
                    delivery_notes: '',
                },
                loading: false,
                submitting: false,
                submitted: false,
                notes: ''
            },
            data: {
                jobs: [],
                jobs_for_new_job: [],
                project_managers: [],
                _project_managers: [],
                open_jobs: [],
                jobs_for_stock: [],
                inventory_items: [],
                inventoryItemCount: 0
            },
            request: null,
        }
    },
    methods: {
        rowIsEditable(cell) {
            return cell.getRow().getData().editable;
        },
        async updateFormRequest(status = null) {
            let uuid = this.$route.params.uuid;

            let data = this.form;

            let url = `/v2/forms/requests/transmittal/${uuid}`

            if (status) {
                url += `/${status}`;
            }

            return this.$http.post(url, data)
                .then(response => {
                    this.request = response.data;
                    return response.data;
                })
        },
        async fetchFormRequest() {
            let uuid = get(this.$route, 'params.uuid');
            if (uuid) {
                return this.$http.get('/v2/forms/requests/transmittal/' + uuid).then(response => {
                    this.request = response.data;
                    this.$log.log(`Loading transmittal data`);
                });
            } else {
                this.$toast.error('No Form Request Found');
                this.$router.push('/');
            }
        },
        async fetchFormData() {

            this.form.loading = true;

            return TransmittalForm.data()
                .then(response => {
                    if (response) {
                        this.data.jobs = get(response, 'data.jobs', []);
                        this.data.open_jobs = get(response, 'data.open_jobs', []);
                        this.data.jobs_for_stock = get(response, 'data.jobs_for_stock', []);

                        this.data._project_managers = get(response, 'data.project_managers', []);
                        this.data.project_managers = Object.values(this.data._project_managers);
                    }
                })
                .finally(() => {
                    this.form.loading = false;
                });
        },
        setInventoryItems(items, count) {
            this.data.inventory_items = items;
            this.form.items = JSON.parse(JSON.stringify(this.groupedInventoryItems));
            this.data.inventoryItemCount = count;

            this.tabulator.replaceData(this.form.items)
                .then(() => {
                    this.table.loading = false;
                }).catch((e) => {
                this.$log.error('Error getting inventory items', e);
            });
        },
        async fetchInventoryItems() {
            const job_number = get(this.form, 'job.job_number');
            const jcco = get(this.form, 'job.company.jcco');

            if (this.tabulator) {
                this.tabulator.clearData();
            }

            this.data.inventory_items = [];
            this.table.loading = true;

            return TransmittalForm.inventory(job_number, jcco)
                .then(response => {
                    const items = get(response, 'data.items', []);
                    const count = get(response, 'data.count', 0);
                    this.setInventoryItems(items, count);
                })
                .catch((e) => {
                    this.table.loading = false;
                    this.$toast.error('Error loading inventory items.');
                });
        },
        changeStep(step) {

            if (this.stepValid(step - 1)) {
                this.updateFormRequest();
                this.step = step;
                this.$log.log(`Step changed to ${this.step}`);
            } else {
                this.$log.warn(`Step validation failure: Step ${step} requires previous step to pass validation.`);
            }
        },
        stepValid(stepNumber) {

            let step = get(this.steps, stepNumber);

            if (step) {
                return step.isValid();
            }
        },
        stepEditable(stepNumber) {
            let valid = true;

            each(this.steps, (step, idx) => {
                if (idx < stepNumber && !step.isValid()) {
                    valid = false;
                }
            });

            return valid;
        },
        getValidFormItems() {

            const filtered = this.form.items.filter((item) => {
                return item.transmittal_quantity > 0;
            });

            return cloneObject(filtered);
        },
        async submitForm() {
            if (this.stepValid(3)) {
                this.form.loading = true;
                this.form.submitting = true;
                this.form.status = 'submitted';

                this.form.items = this.getValidFormItems();
                this.updateFormRequest('submitted')
                    .then(response => {
                        this.form.submitted = true;
                    }).finally(() => {
                    this.form.submitting = false;
                    this.form.loading = false;
                });
            }
        },
        resetForm() {
            this.$http.get('/v2/forms/requests/transmittal/new').then(response => {
                let uuid = get(response, 'data.uuid');
                if (uuid) {
                    this.$router.push({path: '/requests/transmittal/' + uuid});
                }
            });
        },
        addItem() {
            this.addItemDialog = true;
        },
        onItemAdded(item, form) {
            item.transmittal_quantity = form.part_quantity;
            this.data.inventory_items.push(item);
            this.setInventoryItems(this.data.inventory_items, this.data.inventoryItemCount + 1);
            this.updateFormRequest();
        }
    },
    computed: {
        groupedSubmittedItems() {
            let grouped = {};
            let items = get(this.request, 'data.items');

            if (!items) {
                items = [];
            }

            each(items, (item, idx) => {
                console.log('gropitem', item);

                if (!grouped[item.part_combo_name]) {
                    grouped[item.part_combo_name] = item;
                }

                grouped[item.part_combo_name].part_quantity += item.part_quantity;
                grouped[item.part_combo_name].transmittal_quantity += item.transmittal_quantity;

            });

            return grouped;
        },
        groupedInventoryItems() {
            let grouped = {};
            let items = this.data.inventory_items;

            each(items, (item, idx) => {
                if (!grouped[item.part_combo_name]) {
                    grouped[item.part_combo_name] = item;
                } else {
                    grouped[item.part_combo_name].part_quantity += item.part_quantity;
                    grouped[item.part_combo_name].transmittal_quantity += item.transmittal_quantity;
                }
            });

            return Object.values(grouped);
        },
        foremanName() {
            return get(this.form, 'job.foreman.name');
        },
        jobs() {
            let jobs = this.data.jobs;

            // Only return jobs that match the selected project manager
            if (this.form.project_manager) {
                jobs = jobs.filter(job => job.project_manager_user_id === this.form.project_manager.id);
            }

            return jobs;
        },
        projectManagers() {
            return this.data.project_managers;
        },
        jobsForNewJob() {
            if (this.form.job && this.form.job.jcco && this.form.job.jcco < 9999) {
                return this.data.open_jobs.filter(job => job.jcco === this.form.job.jcco);
            }

            return this.data.open_jobs ?? [];
        },
        jobsForStock() {
            return this.data.jobs_for_stock;
        }
    },
    async mounted() {

        this.tabulator = new TabulatorFull(this.$refs.table, {
            data: this.form.items,
            layout: 'fitData',
            layoutColumnsOnNewData: true,
            reactiveData: true,
            validationMode: 'highlight',
            rowFormatter: (row) => {
                const data = row.getData();
                let el = row.getElement();

                if (row && data && data.transmittal_quantity > 0) {
                    if (el) {
                        el.classList.add('row-modified');
                    }
                } else {
                    el.classList.remove('row-modified');
                }
            },
            popupContainer: true,
            resizableColumnFit: true,
            rowHeight: 45,
            columnDefaults: {
                vertAlign: 'middle',
                headerSortTristate: true
            },
            columns: this.columns
        });

        this.tabulator.on('cellEdited', function (cell) {

            try {
                let row = cell.getRow();
                if (row) {
                    setTimeout(() => {
                        row.reformat();
                        row.validate();
                    }, 200);
                }
            } catch (e) {
                this.$log.error('Error in cellEdited event', e);
            }
        });
    },
    async created() {
        this.form.loading = true;

        await this.fetchFormData();
        await this.fetchFormRequest();

        let job = get(this.request, 'data.job');
        let mode = get(this.request, 'data.mode');
        let delivery = get(this.request, 'data.delivery');
        let newJob = get(this.request, 'data.newJob');

        if (mode) {
            this.form.mode = mode;
        }

        if (job) {
            this.form.job = job;
            this.step = 2;
        }

        if (newJob) {
            this.form.newJob = newJob;
            this.step = 3;
        }

        if (mode === 'warehouseToJobsite' && delivery) {
            let contact = get(this.request, 'data.delivery.site_contact_name');
            let date = get(this.request, 'data.delivery.delivery_date');
            let _window = get(this.request, 'data.delivery.delivery_window');

            if (contact) {
                this.form.delivery.site_contact_name = contact;
            }

            if (date) {
                this.form.delivery.delivery_date = date;
            }

            if (_window) {
                this.form.delivery.delivery_window = _window;
            }

            if (this.stepValid(2)) {
                this.step = 3;
            }
        }

        this.form.loading = false;
        this.form.submitted = false;
    }
}
</script>

<template>
    <VContainer>
        <PageTitle>Transmittal Request</PageTitle>

        <FormSubmittedMessage
            v-show="form.submitted"
            :on-click="resetForm"
            button-text="New Transmittal Request"
            title="Transmittal Request Submitted"
        >
            Transmittal Request Has Been Submitted
        </FormSubmittedMessage>

        <div v-if="!form.submitted">

            <VStepper
                v-model="step"
                non-linear
                vertical
            >

                <VStepperStep
                    :key="1"
                    :complete="step > 1"
                    :rules="[v => stepValid(1)]"
                    complete-icon="mdi-check"
                    edit-icon="mdi-check"
                    editable
                    error-icon="mdi-alert-octagon"
                    step="1"
                >
                    <div
                        v-if="this.form.job"
                        key="1"
                    >
                        <div
                            class="font-italic grey--text my-1"
                        >
                            {{ this.form.job.job_number }}
                        </div>
                        <div>
                            {{ this.form.job.description }}
                        </div>
                    </div>
                    <div v-else key="2">
                        Select Job
                    </div>

                    <small v-if="!stepValid(1)">
                        Please select a job
                    </small>
                </VStepperStep>
                <VStepperContent
                    step="1"
                >
                    <VCard>
                        <VCardSubtitle>Select Job</VCardSubtitle>
                        <VCardText>
                            <VRow>
                                <VCol>
                                    <ProjectManagerAutocomplete
                                        v-model="form.project_manager"
                                        :items="projectManagers"
                                        :loading="form.loading"
                                    />
                                </VCol>
                                <VCol>
                                    <JobsAutocomplete
                                        v-model="form.job"
                                        :items="jobs"
                                        :jobs="data.jobs"
                                        :loading="form.loading"
                                    />
                                </VCol>
                            </VRow>
                            <VRow>
                                <VCol>
                                    <VBtn
                                        :disabled="!stepValid(1)"
                                        color="primary"
                                        @click="changeStep(2)"
                                    >
                                        Continue
                                    </VBtn>
                                </VCol>
                            </VRow>
                        </VCardText>
                    </VCard>
                </VStepperContent>

                <VStepperStep
                    :key="2"
                    :complete="step > 2"
                    :rules="[v => stepValid(2)]"
                    complete-icon="mdi-check"
                    edit-icon="mdi-check"
                    editable
                    error-icon="mdi-alert-octagon"
                    step="2"
                >
                    <div
                        v-if="stepValid(2) && form.mode && step > 2"
                        key="mode-1"
                    >
                        <div v-if="form.mode === 'warehouseToJobsite'">
                            <VRow>
                                <VCol>
                                    <i class="grey--text">
                                        Method
                                    </i> <br>
                                    {{ methodNames[form.mode] }}
                                </VCol>
                                <VCol>
                                    <i class="grey--text">Contact</i> <br>
                                    {{ form.delivery.site_contact_name }}
                                </VCol>
                                <VCol>
                                    <i class="grey--text">Date</i> <br>
                                    {{ form.delivery.delivery_date }}
                                </VCol>
                                <VCol>
                                    <i class="grey--text">Window</i> <br>
                                    {{ form.delivery.delivery_window }}
                                </VCol>
                            </VRow>
                        </div>
                        <div v-if="form.mode === 'switchToJob' && form.newJob">
                            <VRow>
                                <VCol>
                                    <div class="grey--text font-italic mb-1">
                                        Method
                                    </div>
                                    {{ methodNames[form.mode] }}
                                </VCol>
                                <VCol>
                                    <div class="grey--text font-italic mb-1">Job</div>
                                    {{ form.newJob.job_number }}
                                </VCol>
                                <VCol>
                                    <div class="grey--text font-italic mb-1">Description</div>
                                    {{ form.newJob.description }}
                                </VCol>
                            </VRow>
                        </div>

                        <div v-if="form.mode === 'switchToStock'">
                            <VRow>
                                <VCol>
                                    <div class="grey--text font-italic mb-1">
                                        Method
                                    </div>
                                    {{ methodNames[form.mode] }}
                                </VCol>
                                <VCol>
                                    <div class="grey--text font-italic mb-1">Location</div>
                                    {{ form.newJob.description }}
                                </VCol>
                            </VRow>
                        </div>
                    </div>
                    <div
                        v-else
                        key="mode-2"
                    >
                        Select Method
                    </div>

                    <small v-if="!stepValid(2)">
                        Please complete form
                    </small>
                </VStepperStep>
                <VStepperContent
                    step="2"
                >
                    <VTabs
                        v-model="form.mode"
                        background-color="tabHeaderBackground"
                        centered
                        fixed-tabs
                    >
                        <VTab href="#warehouseToJobsite">From Warehouse</VTab>
                        <VTab href="#switchToJob">From Job/Stock to Job</VTab>
                        <VTab href="#switchToStock">From Job/Stock to Stock</VTab>
                    </VTabs>

                    <VTabsItems
                        v-model="form.mode"
                    >
                        <VTabItem
                            class="my-8 mx-4"
                            value="warehouseToJobsite"
                        >
                            <VRow>
                                <VCol>
                                    <TextInput
                                        v-model="form.delivery.site_contact_name"
                                        :rules="form.mode === 'warehouseToJobsite' ? [rules.required] : []"
                                        label="Site Contact Name"
                                    />
                                </VCol>
                                <VCol>
                                    <DatePicker
                                        v-model="form.delivery.delivery_date"
                                        :rules="form.mode === 'warehouseToJobsite' ? [rules.required] : []"
                                        hint="YYYY-MM-DD Format"
                                        label="Delivery Date"
                                    />
                                </VCol>
                                <VCol>
                                    <DeliveryWindowSelect
                                        v-model="form.delivery.delivery_window"
                                        :rules="form.mode === 'warehouseToJobsite'? [rules.required] : []"
                                        label="Delivery Window"
                                    />
                                </VCol>
                            </VRow>
                            <VRow>
                                <VCol>
                                    <NotesTextField
                                        v-model="form.delivery.delivery_notes"
                                        label="Delivery Notes"
                                    />
                                </VCol>
                            </VRow>
                        </VTabItem>

                        <VTabItem
                            class="my-8 mx-4"
                            value="switchToJob"
                        >
                            <JobsAutocomplete
                                v-model="form.newJob"
                                :items="data.jobs_for_new_job"
                                :jobs="jobsForNewJob"
                                :rules="form.mode ==='switchToJob' ? [rules.required] : []"
                            />

                            <v-list
                                v-if="form.newJob"
                                class="mt-3"
                                color="jobDetailsBackground"
                                elevation="2"
                            >
                                <!-- Display part Info -->
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-list-item-subtitle>Job Name</v-list-item-subtitle>
                                                <v-list-item-title>{{
                                                        form.newJob.description
                                                    }}
                                                </v-list-item-title>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-list-item-subtitle>Manager</v-list-item-subtitle>
                                                <v-list-item-title>{{
                                                        (form.newJob.project_manager ? form.newJob.project_manager.name : '')
                                                    }}
                                                </v-list-item-title>
                                            </v-col>
                                            <v-col cols="12" sm="3">
                                                <v-list-item-subtitle>Foreman</v-list-item-subtitle>
                                                <v-list-item-title>{{
                                                        (form.newJob.foreman ? form.newJob.foreman.name : '')
                                                    }}
                                                </v-list-item-title>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </VTabItem>

                        <VTabItem
                            class="my-8 mx-4"
                            value="switchToStock"
                        >
                            <JobsAutocomplete
                                v-model="form.newJob"
                                :items="data.jobs_for_stock"
                                :jobs="jobsForStock"
                                :rules="form.mode ==='switchToStock'? [rules.required] : []"
                            >
                            </JobsAutocomplete>
                        </VTabItem>
                    </VTabsItems>

                    <VRow class="mt-6">
                        <VCol>
                            <VBtn
                                :disabled="!stepValid(2)"
                                color="primary"
                                @click="changeStep(3)"
                            >
                                Continue
                            </VBtn>
                        </VCol>
                    </VRow>
                </VStepperContent>

                <VStepperStep
                    :key="3"
                    :complete="step > 3"
                    :rules="[v => stepValid(3)]"
                    complete-icon="mdi-check"
                    edit-icon="mdi-check"
                    editable
                    error-icon="mdi-alert-octagon"
                    step="3"
                >
                    Enter Quantities
                </VStepperStep>
                <VStepperContent
                    step="3"
                >
                    <div ref="table"></div>

                    <v-btn
                        v-show="user.is_admin"
                        class="mt-2"
                        color="primary"
                        small
                        @click="addItem"
                    >
                        <v-icon left>mdi-plus</v-icon>
                        Add Item
                    </v-btn>

                    <VDivider class="my-4"></VDivider>

                    <VRow>
                        <VCol>
                            <VSheet class="pa-3">
                                <NotesTextField
                                    v-model="form.notes"
                                    label="Transmittal Notes"
                                ></NotesTextField>
                            </VSheet>
                        </VCol>
                    </VRow>
                    <VRow class="mt-6">
                        <VCol>
                            <VBtn
                                :disabled="!stepValid(3) || form.submitting || form.loading"
                                :loading="form.loading"
                                color="primary"
                                @click="submitForm()"
                            >
                                Submit Request for Transmittal
                            </VBtn>
                        </VCol>
                    </VRow>
                </VStepperContent>
            </VStepper>
        </div>

        <v-dialog
            v-model="form.submitting"
            max-width="400"
            persistent
        >
            <v-card>
                <v-card-title>Submitting Request for Transmittal</v-card-title>
                <v-card-text class="text-center">
                    <v-alert
                        v-show="form.submitted"
                        border="left"
                        prominent
                        text
                        type="success"
                    >
                        Transmittal Request Submitted
                    </v-alert>

                    <v-progress-linear
                        v-show="!form.submitted"
                        :height="20"
                        class="my-4"
                        color="primary"
                        indeterminate
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <AddLineItemDialog
            v-show="user.is_admin"
            v-model="addItemDialog"
            @saved="onItemAdded"
        ></AddLineItemDialog>
    </VContainer>
</template>

<style scoped>

</style>