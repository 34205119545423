import vehicleApiMixin from "@/mixins/Api/vehicle-api.mixin";
import { get } from "@/client/utilities/Object";
export default {
    name: 'VehicleAutocomplete',
    inheritAttrs: false,
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    mixins: [vehicleApiMixin],
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        get,
        async onFocus() {
            console.log(this.vehicles.length);
            if (this.items.length === 0) {
                this.vehicles = await this.fetchVehicles();
            }
            else {
                this.vehicles = this.items;
            }
        }
    }
};
