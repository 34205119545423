export default {
    name: "KeyValueChip",
    props: {
        title: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        tooltip: {
            type: Boolean,
            default: false
        },
        tooltipValue: {
            type: String,
            default: ""
        },
        tooltipPrefix: {
            type: String,
            default: ""
        }
    }
};
