import { get } from "@/client/utilities/Object";
export default {
    name: 'VehicleAutocompleteWithoutFetch',
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        get
    }
};
