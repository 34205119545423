import PageTitle from "@/components/PageTitle.vue";
import TextInput from "@/components/TextInput.vue";
import DatePicker from "@/components/V2/DatePicker.vue";
import ToolAutocomplete from "@/components/ToolAutocomplete.vue";
import { get } from "@/client/utilities/Object";
import cloneObject from "@/common/clone-object";
import PhotoManager from "@/components/Photos/PhotoManager.vue";
import CertificationDocumentManager from "@/components/CertificationDocuments/CertificationDocumentManager.vue";
import JobAutocomplete from "@/components/Job/JobAutocomplete.vue";
import AddToolAccessoryComponent from "@/components/AddToolAccessoryComponent.vue";
import DividingHeader from "@/components/DividingHeader.vue";
import AddToolConsumableComponent from "@/components/AddToolConsumableComponent.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import CurrencyInput from "@/components/Input/CurrencyInput.vue";
import NotesTextField from "@/components/NotesTextField.vue";
import ToolModelAutocomplete from "@/components/Tools/Autocompletes/ToolModelAutocomplete.vue";
import ToolStatusAutocomplete from "@/components/Tools/Autocompletes/ToolStatusAutocomplete.vue";
import VehicleAutocomplete from "@/components/VehicleAutocomplete.vue";
import * as dayjs from "dayjs";
import LoadingDialog from "@/components/Dialogs/LoadingDialog.vue";
export default {
    components: {
        LoadingDialog,
        VehicleAutocomplete,
        ToolStatusAutocomplete,
        ToolModelAutocomplete,
        NotesTextField,
        CurrencyInput,
        ConfirmDialog,
        AddToolConsumableComponent,
        DividingHeader,
        AddToolAccessoryComponent,
        JobAutocomplete,
        CertificationDocumentManager, PhotoManager, ToolAutocomplete, DatePicker, TextInput, PageTitle
    },
    title: 'Add Tool',
    name: 'CreateTool',
    data() {
        return {
            rules: {
                required: [v => !!v || 'Required']
            },
            data: {
                tool_models: [],
                tool: null,
                racks: [],
            },
            tool: null,
            breadcrumbs: [
                {
                    text: 'Home',
                    disabled: false,
                    to: '/',
                },
                {
                    text: 'Tools',
                    disabled: false,
                    to: '/tools/list',
                }
            ],
            showProductSearch: false,
            loadingTool: false,
            form: {
                valid: false,
                id: null,
                locationType: 'warehouse',
                tool_type: 'tool',
                tool_model: null,
                job: null,
                photos: [],
                notes: null,
                sku: null,
                quantity: 1,
                vehicle: null,
                documents: [],
                status: {
                    "id": 1,
                    "name": "Available",
                    "slug": "available",
                    "description": null,
                    "can_be_reserved": true,
                    "deleted_at": null,
                    "created_at": "2024-01-04T22:41:11.000000Z",
                    "updated_at": "2024-01-04T22:41:11.000000Z"
                },
                rack: null,
                section: null,
                sub_section: null,
                price: null,
                product: null,
                manufacturer: null,
                serial_number: null,
                name: null,
                description: null,
                requires_certification: false,
                certification: {
                    id: null,
                    document: null,
                    documents: [],
                    certified_at: null,
                    expires_at: null,
                    required: false
                },
                category: null,
                accessories: [],
                consumables: []
            },
            skeletons: {
                accessory: {
                    id: null,
                    name: null,
                    tool_type: 'accessory',
                    description: null,
                    locationType: 'acc-warehouse',
                    photos: [],
                    serial_number: null,
                    rack: null,
                    section: null,
                    sub_section: null,
                    requiresCertification: true,
                    certification: {
                        documents: [],
                        startDate: null,
                        endDate: null,
                    },
                    category: null,
                    price: null
                },
                consumable: {
                    id: null,
                    locationType: 'warehouse',
                    tool_type: 'consumable',
                    rack: null,
                    section: null,
                    sub_section: null,
                    name: null,
                    description: '',
                    category: null,
                    price: null,
                    photos: []
                }
            },
            statuses: [],
            categories: [],
            dracks: [],
            dsections: [],
            dsub_sections: [],
            rack: null,
            section: null,
            manufacturers: [],
            valid: false,
            submitting: false,
            submitted: false,
            validationErrors: [],
        };
    },
    watch: {
        'form.product'(p) {
            if (p) {
                this.form.name = this.form.product.title;
                this.form.description = this.form.product.description;
                this.form.sku = this.form.product.sku;
                const images = this.form.product.images;
                for (let i = 0; i < images.length; i++) {
                    let url = images[i].url;
                    if (!url) {
                        url = images[i].original_url;
                    }
                    let photo = {
                        id: null,
                        url: url,
                        original_filename: '',
                        thumbnail: {
                            url: url
                        }
                    };
                    //  this.form.photos.push(photo);
                }
            }
            else {
                this.form.name = null;
                this.form.description = null;
                this.form.sku = null;
                // this.form.photos = [];
            }
        },
        'form.category'(val) {
            if (val && val.requires_certification) {
                this.form.requires_certification = true;
            }
        },
        'form.tool_model'(val) {
            if (val) {
                this.form.name = val.name;
                9;
                this.form.description = val.description;
                this.form.sku = val.sku;
                this.form.price = val.price;
                this.form.manufacturer = val.tool_manufacturer;
                this.form.category = val.tool_category;
                this.form.photos = val.photos;
            }
            else {
                this.form.name = null;
                this.form.description = null;
                this.form.sku = null;
                this.form.price = null;
                this.form.manufacturer = null;
                this.form.category = null;
                this.form.photos = [];
            }
        }
    },
    computed: {
        editing() {
            return this.$route.params.id > 0;
        }
    },
    methods: {
        get,
        submit() {
            if (this.$refs.createToolForm.validate() && !this.submitting) {
                if (this.data.tool) {
                    this.form.tool_id = this.data.tool.id;
                    this.form.quantity = 1;
                }
                this.submitting = true;
                console.log('submitting tool', this.submitting);
                this.$http.post('/v3/tools', this.form).then((response) => {
                    this.$router.push({ path: '/tools/list' });
                }).finally(() => {
                    this.submitting = false;
                    this.submitted = true;
                });
            }
            else {
                this.validationErrors = this.$refs.createToolForm.inputs.filter(input => !input.valid)
                    .map(input => input.errorBucket)
                    .flat();
                this.submitting = false;
                this.submitted = false;
            }
        },
        async fetchData() {
            const toolId = this.$route.params.id;
            this.loadingTool = true;
            return this.$http.get('/v3/tools/forms/create/data', { params: { tool_id: toolId } }).then((response) => {
                this.data.racks = get(response, 'data.racks', []);
                this.categories = get(response, 'data.categories', []);
                this.manufacturers = get(response, 'data.manufacturers', []);
                this.tool = get(response, 'data.tool', null);
            }).finally(() => {
                this.loadingTool = false;
            });
        },
        async fetchStatuses() {
            return this.$http.get('/v3/tools/statuses').then((response) => {
                this.statuses = get(response, 'data.statuses', []);
                return this.statuses;
            });
        },
        async fetchRacks() {
            return this.$http.get('/v3/tools/locations/racks').then((response) => {
                this.data.racks = get(response, 'data.racks', []);
                return this.data.racks;
            });
        },
        async fetchManufacturers() {
            return this.$http.get('/v3/tools/manufacturers').then((response) => {
                this.manufacturers = get(response, 'data.manufacturers', []);
                return this.manufacturers;
            });
        },
        async fetchCategories() {
            return this.$http.get('/v3/tools/categories').then((response) => {
                this.categories = response.data;
                return this.categories;
            });
        },
        async fetchTool() {
            let id = this.$route.params.id;
            if (id) {
                return this.$http.get('/v3/tools/' + id).then((response) => {
                    this.tool = response.data;
                    return this.tool;
                });
            }
        },
        async fetchToolModels() {
            return this.$http.get('/v3/tools/models').then((response) => {
                this.data.tool_models = get(response, 'data.tool_models', []);
                return this.data.tool_models;
            });
        },
        getRack(rackId) {
            if (rackId && this.data.racks) {
                const rack = this.data.racks.filter((r) => r.id === rackId);
                if (rack && rack[0]) {
                    return cloneObject(rack[0]);
                }
            }
            return [];
        },
        getSection(rack, sectionId) {
            if (rack && sectionId && rack.sections) {
                const section = rack.sections.filter((s) => s.id === sectionId);
                if (section) {
                    return cloneObject(section[0]);
                }
            }
            return [];
        },
        getSubSection(section, subSectionId) {
            if (section && subSectionId && section.sub_sections) {
                const subSection = section.sub_sections.filter((s) => s.id === subSectionId);
                if (subSection) {
                    return cloneObject(subSection[0]);
                }
            }
            return [];
        },
        racks() {
            return this.data.racks;
        },
        sections(rack) {
            return get(rack, 'sections', []);
        },
        subSections(section) {
            return get(section, 'sub_sections', []);
        }
    },
    async created() {
        await this.fetchData();
        if (this.$route.params.id && this.tool) {
            let tool = this.tool;
            this.breadcrumbs.push({
                text: tool.name,
                disabled: true
            });
            document.title = tool.name + ' - Edit Tool - Virtual Warehouse';
            this.form.id = tool.id;
            if (tool.job) {
                this.form.job = tool.job;
                this.form.locationType = 'jobsite';
            }
            else if (tool.vehicle) {
                this.form.vehicle = tool.vehicle;
                this.form.locationType = 'vehicle';
            }
            else {
                this.form.locationType = 'warehouse';
                const rack = this.getRack(tool.tool_rack_id);
                this.form.rack = get(rack, 'id', false) ? rack : null;
                const section = this.getSection(this.form.rack, tool.tool_section_id);
                this.form.section = get(section, 'id', false) ? section : null;
                const subSection = this.getSubSection(this.form.section, tool.tool_sub_section_id);
                this.form.sub_section = get(subSection, 'id', false) ? subSection : null;
            }
            this.form.name = tool.name;
            this.form.description = tool.description;
            this.form.category = tool.tool_category;
            this.form.manufacturer = tool.tool_manufacturer;
            this.form.photos = tool.photos;
            this.form.notes = tool.notes;
            this.form.status = tool.tool_status;
            this.form.price = tool.price;
            this.form.sku = tool.sku;
            this.form.serial_number = tool.serial_number;
            this.form.requires_certification = tool.requires_certification;
            this.form.certification.required = tool.requires_certification;
            let documents = [];
            const certifications = tool.tool_certifications;
            let certified_at = null;
            let expires_at = null;
            let id = null;
            if (certifications) {
                for (const c of certifications) {
                    id = c.id;
                    for (const doc of c.documents) {
                        doc.certified_at = c.certified_at;
                        doc.expires_at = c.expires_at;
                        certified_at = dayjs(doc.certified_at).format('YYYY-MM-DD');
                        expires_at = dayjs(doc.expires_at).format('YYYY-MM-DD');
                        documents.push(doc);
                    }
                }
            }
            this.form.certification.id = id;
            this.form.certification.documents = documents;
            this.form.certification.certified_at = certified_at;
            this.form.certification.expires_at = expires_at;
        }
        else {
            this.breadcrumbs.push({
                text: 'Add Tool',
                disabled: true
            });
        }
    }
};
