<script>
import PageTitle from "@/components/PageTitle.vue";
import {get} from "@/client/utilities/Object";
import debounce from "@/client/utilities/Debounce";
import KeyValueChip from "@/components/Chips/KeyValueChip.vue";
import PhotoThumbnail from "@/components/Photos/PhotoThumbnail.vue";
import ConfirmDialog from "@/components/V2/ConfirmDialog.vue";
import JobInfoChip from "@/components/Chips/JobInfoChip.vue";
import Axios from "axios";
import JobAutocompleteInput from "@/components/Job/JobAutocompleteInput.vue";
import VehicleAutocompleteWithoutFetch from "@/components/VehicleAutocompleteWithoutFetch.vue";
import EmployeeAutocompleteInput from "@/components/V2/EmployeeAutocompleteInput.vue";
import SelectableTypeAutocompleteInput from "@/components/SelectableTypeAutocompleteInput.vue";

export default {
    name: 'Tools',
    components: {
        SelectableTypeAutocompleteInput,
        EmployeeAutocompleteInput,
        VehicleAutocompleteWithoutFetch,
        JobAutocompleteInput,
        JobInfoChip,
        ConfirmDialog,
        PhotoThumbnail,
        KeyValueChip,
        PageTitle
    },
    title: 'Tools',
    data() {
        return {
            selected: {
                job: null,
                vehicle: null,
                employee: null,
                company: null,
            },
            selectedTab: null,
            tabs: [
                {icon: 'mdi-magnify', label: 'Search', disabled: false},
                {icon: 'mdi-toolbox-outline', label: 'Job', disabled: false},
                {icon: 'mdi-truck-off-road', label: 'Vehicle', disabled: false},
                {icon: 'mdi-account-tie', label: 'Employee', disabled: false},
                {icon: 'mdi-domain', label: 'Company', disabled: false},
            ],
            job: null,
            employee: null,
            vehicle: null,
            company: null,
            tools: [],
            expanded: [],
            headers: [
                {text: '', value: 'edit', sortable: false, divider: true, width: 50},
                {text: 'ID', value: 'id', sortable: false, width: 30, divider: true},
                {text: 'Photo', value: 'photo', sortable: false, width: 50, divider: true},
                {text: 'Name', value: 'name', sortable: false, width: 200, divider: true},
                {text: 'Category', value: 'tool_category.name', sortable: false, width: 100, divider: true},
                {text: 'Model', value: 'sku', sortable: false, divider: true, width: 200},
                {text: 'Serial', value: 'serial_number', sortable: false, divider: true},
                {text: 'Certified', value: 'certification', sortable: false, divider: true},
                {text: 'Status', value: 'tool_status.name', sortable: false, divider: true},
                {text: 'Location', value: 'location', sortable: false, divider: true},
                {text: 'Manufacturer', value: 'tool_manufacturer.name', sortable: false, divider: true},
                {text: 'Type', value: 'tool_type', sortable: false, divider: true},
                {text: '', value: 'edit', sortable: false, divider: true, width: 50},
                {text: '', value: 'delete', sortable: false, divider: true, width: 50},
                {text: 'Updated', value: 'updated_at', sortable: true, divider: true},
            ],
            data: {
                jobs: [],
                vehicles: [],
                employees: [],
                companies: [],
            },
            loading: true,
            search: '',
            options: {
                page: 1,
                itemsPerPage: 15,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false,
            },
            debounceSearch: debounce(this.fetchTools, 500),
            total: 0,
        }
    },
    watch: {
        options: {
            handler(val) {
                this.fetchTools();
            },
            deep: true,
        },
        search(query) {

        },
        selectedTab(tab) {
            if (tab === 0) {
                this.job = null;
                this.vehicle = null;
                this.employee = null;
                this.company = null;
            }

            if (tab === 1) {
                this.search = null;
                this.employee = null;
                this.vehicle = null;
                this.company = null;
            }

            if (tab === 2) {
                this.search = null;
                this.job = null;
                this.vehicle = null;
                this.company = null;
            }

            if (tab === 3) {
                this.search = null;
                this.job = null;
                this.vehicle = null;
                this.company = null;
            }

            if (tab === 4) {
                this.search = null;
                this.employee = null;
                this.job = null;
                this.vehicle = null;
            }
        }
    },
    methods: {
        get,
        editTool(toolId, type = 'tool') {
            if (type === 'tool') {
                this.$router.push({name: 'EditTool', params: {id: toolId}})
            }
            if (type === 'accessory') {
                this.$router.push({name: 'EditAccessory', params: {id: toolId}})
            }
            if (type === 'consumable') {
                this.$router.push({name: 'EditConsumable', params: {id: toolId}})
            }
        },
        fetchTools() {
            this.loading = true;

            this.$http.get(`/v3/tools`, {params: this.fetchToolsParams}).then(response => {
                this.tools = get(response, 'data.tools.data', []);
                this.total = get(response, 'data.tools.total', 0);
            }).finally(() => {
                this.loading = false;
            });
        },
        async exportToExcel() {
            const params = new URLSearchParams();

            if (this.search) {
                params.append('search', this.search);
            }

            if (this.job) {
                params.append('job_id', get(this.job, 'id'));
            }

            if (this.vehicle) {
                params.append('vehicle_id', get(this.vehicle, 'id'));
            }

            if (this.employee) {
                params.append('employee_id', get(this.employee, 'id'));
            }

            if (this.company) {
                params.append('company_id', get(this.company, 'id'));
            }

            window.open(`/api/v3/tools/export/excel?${params.toString()}`, '_blank');
        },
        async fetchData() {
            const response = await Axios.get('/v3/tools/reports/data');
            this.data = response.data;
        },
        remove(toolId) {
            this.$http.delete(`/v3/tools/${toolId}`).then(response => {
                this.fetchTools();
            });
        }
    },
    computed: {
        fetchToolsParams() {

            const page = this.options.page || 1;
            const search = this.search || null;

            return {
                page: page,
                search: search,
                job_id: get(this.job, 'id'),
                vehicle_id: get(this.vehicle, 'id'),
                employee_id: get(this.employee, 'id'),
                company_id: get(this.company, 'id'),
                sortBy: this.options.sortBy,
                sortByDesc: this.options.sortDesc,
            };
        }
    },
    created() {
        this.fetchData();
    },
}
</script>

<template>
    <v-container fluid>
        <PageTitle></PageTitle>

        <v-tabs v-model="selectedTab" background-color="tabHeaderBackground">
            <v-tab v-for="(tab, idx) in tabs" :key="idx">
                <v-icon left small>{{ tab.icon }}</v-icon>
                {{ tab.label }}
            </v-tab>
            <v-tab key="export">
                <v-icon left>mdi-download-box</v-icon>
                Export
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
            <v-tab-item
                key="0"
            >
                <v-card>
                    <v-card-text>
                        <v-text-field
                            v-model="search"
                            hide-details="auto"
                            label="Search"
                            outlined
                            clearable
                            prepend-inner-icon="mdi-magnify"
                            @keyup="debounceSearch"
                        />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item key="1">
                <v-card>
                    <v-card-text>
                        <job-autocomplete-input
                            v-model="job"
                            :items="data.jobs"
                            @change="fetchTools"
                        />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item key="2">
                <v-card>
                    <v-card-text>
                        <vehicle-autocomplete-without-fetch
                            v-model="vehicle"
                            :items="data.vehicles"
                            @change="fetchTools"
                        />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item key="3">
                <v-card>
                    <v-card-text>
                        <employee-autocomplete-input
                            v-model="employee"
                            :items="data.employees"
                            @change="fetchTools"
                        />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item key="4">
                <v-card>
                    <v-card-text>
                        <v-autocomplete
                            clearable
                            outlined
                            return-object
                            hide-details="auto"
                            v-model="company"
                            item-text="name"
                            item-value="id"
                            label="Select Company"
                            :items="data.companies"
                            @change="fetchTools"
                        />
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item key="export">
                <v-card>
                    <v-card-text>
                        <v-btn
                            small
                            color="primary"
                            @click="exportToExcel"
                        >
                            <v-icon left>mdi-file-excel</v-icon>
                            Export to Excel
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <v-data-table
            class="mt-3"
            :footer-props="{disableItemsPerPage: true}"
            :headers="headers"
            :items="tools"
            :items-per-page="30"
            :loading="loading"
            :options.sync="options"
            :search.sync="search"
            :server-items-length="total"
            calculate-widths
            elevation="5"
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-simple-table class="my-10 mx-5 elevation-4" dense>
                        <thead class="blue-grey darken-4">
                        <tr>
                            <td></td>
                            <td>Name</td>
                            <td>Model #</td>
                            <td>Type</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="py-2">
                                <PhotoThumbnail :photo="get(item, 'photo')" width="40"/>
                            </td>
                            <td>
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.sku }}
                            </td>
                            <td>
                                {{ item.tool_type }}
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </td>
            </template>

            <template v-slot:item.photo="{ item }">
                <PhotoThumbnail v-if="item.photo" :photo="get(item, 'photo')" class="ma-0" width="40"/>
            </template>

            <template v-slot:item.updated_at="{ item }">
                {{ item.updated_at | date }}
            </template>

            <template v-slot:item.delete="{ item }">
                <ConfirmDialog
                    :message="`Delete tool Id ${item.id} | ${item.sku}  |  SN: ${item.serial_number}?`"
                    color="delete"
                    small
                    @confirm="remove(item.id)"
                >
                    <v-icon small>mdi-delete</v-icon>
                </ConfirmDialog>
            </template>

            <template v-slot:item.edit="{ item }">
                <v-btn
                    color="green darken-3"
                    small
                    style="min-width:auto;"
                    @click="editTool(item.id, item.tool_type)"
                >
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.duplicate="{ item }">
                <v-menu
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            small
                            text
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list color="grey darken-3">
                        <v-list-item color="primary" link>
                            <v-list-item-icon>
                                <v-icon>mdi-content-duplicate</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Duplicate
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template v-slot:item.certification="{ item }">
                <span v-if="item.requires_certification">
                    <KeyValueChip
                        :color="(get(item, 'tool_certification.is_valid', false) ? 'success' : 'error')"
                        :tooltip-value="get(item, 'tool_certification.expires_at', '1970-01-01') | date('YYYY-MM-DD')"
                        small
                        tooltip
                        tooltip-prefix="Expires On: "
                    >
                        <v-icon
                            small>mdi-{{
                                (get(item, 'tool_certification.is_valid', false) ? 'check' : 'alert')
                            }}-decagram</v-icon>
                    </KeyValueChip>
                </span>
            </template>

            <template v-slot:item.location="{ item, index }">
                <JobInfoChip v-if="item.job"
                             :key="`job-if-${index}`"
                             :company-slug="get(item, 'job.company.slug')"
                             :job-number="get(item, 'job.job_number')"
                />
                <JobInfoChip
                    v-else-if="item.vehicle"
                    :job-number="get(item,'vehicle.name')"
                    company-slug="Vehicle"
                />
                <JobInfoChip
                    v-else-if="item.employee"
                    :job-number="get(item, 'employee.name')"
                    company-slug="Employee"
                />
                <span v-else :key="`job-else-${index}`">
                    {{ item.tool_location_key }}
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>

<style>
.v-data-table-header tr th {
    background: #37474f !important;
}
</style>