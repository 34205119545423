import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import About from "../views/About.vue";
import ReceiveForm from "../views/ReceiveForm.vue";
import TransmitForm from "../views/TransmitForm.vue";
import InventoryForm from "../views/InventoryForm.vue";
import PurchaseOrderForm from "../views/PurchaseOrderForm.vue";
import Styles from "../views/Styles.vue";
import RestockForm from "../views/RestockForm.vue";
import ReceiveOwnerMaterialsForm from "../views/ReceiveOwnerMaterialsForm.vue";
import KitForm from "../views/KitForm.vue";
import RmaForm from "../views/RmaForm.vue";
import WarehouseInventory from "../views/WarehouseInventory.vue";
import TestDymo from "../views/TestDymo.vue";
import TransmitFormV2 from "../views/V2/TransmitForm-V2.vue";
import PageNotFound from "../views/PageNotFound.vue";
import ReceiveFormV2 from "../views/V2/ReceiveForm-V2.vue";
import Axios from "axios";
import {get} from "@/client/utilities/Object";
import ApproveReceivingRequestForm from "@/views/V3/Receiving/ApproveReceivingRequestForm.vue";
import TransmittalRequestForm from "@/views/V3/Transmittal/TransmittalRequestForm.vue";
import ApprovalRequests from "@/views/V3/ApprovalRequests.vue";
import ApproveTransmittalRequest from "@/views/V3/Transmittal/ApproveTransmittalRequest.vue";
import ReceivingRequest from "@/views/V3/Receiving/ReceivingRequest.vue";
import RmaRequestForm from "@/views/V3/RMA/RmaRequestForm.vue";
import ApproveRmaRequest from "@/views/V3/RMA/ApproveRmaRequest.vue";
import PhotoReport from "@/views/V3/Reports/PhotoReport.vue";
import TestLocations from "@/views/TestLocations.vue";
import NotificationSubscriptions from "@/views/V3/Notifications/NotificationSubscriptions.vue";
import CreateSubscriptionForm from "@/components/Notification/Subscription/CreateSubscriptionForm.vue";
import ToolCategories from "@/views/V3/Tools/ToolCategories.vue";
import CreateTool from "@/views/V3/Tools/CreateTool.vue";
import Tools from "@/views/V3/Tools/Tools.vue";
import Certifications from "@/views/V3/Tools/Certifications.vue";
import ToolCheckout from "@/views/V3/Tools/ToolCheckout.vue";
import CreateConsumable from "@/views/V3/Tools/CreateConsumable.vue";
import CreateAccessory from "@/views/V3/Tools/CreateAccessory.vue";
import CreateToolKit from "@/views/V3/Tools/CreateToolKit.vue";
import ToolCheckoutApproval from "@/views/V3/Tools/ToolCheckoutApproval.vue";
import ToolKits from "@/views/V3/Tools/ToolKits.vue";
import ToolCheckIn from "@/views/V3/Tools/ToolCheckIn.vue";
import ExportToolData from "@/views/V3/Tools/Data/ExportToolData.vue";
import ListVehicles from "@/views/V3/Vehicles/ListVehicles.vue";
import CreateVehicle from "@/views/V3/Vehicles/CreateVehicle.vue";
import ListDevices from "@/views/V3/Tools/Devices/ListDevices.vue";
import CreateDevice from "@/views/V3/Tools/Devices/CreateDevice.vue";
import MaterialRequests from "@/views/V3/Materials/MaterialRequests.vue";
import CreateMaterialRequest from "@/views/V3/Materials/CreateMaterialRequest.vue";
import ToolReport from "@/views/V3/Reports/ToolReport.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/materials/requests/create',
        name: 'CreateMaterialRequest',
        component: CreateMaterialRequest
    },
    {
        path: '/materials/requests/:id',
        name: 'EditMaterialRequest',
        component: CreateMaterialRequest
    },
    {
        path: '/materials/requests',
        name: 'MaterialRequest',
        component: MaterialRequests
    },
    {
        path: '/tools/devices/create',
        name: 'CreateDevice',
        component: CreateDevice
    },
    {
        path: '/tools/devices/list',
        name: 'ListDevices',
        component: ListDevices
    },
    {
        path: '/tools/devices/:id',
        name: 'EditDevice',
        component: CreateDevice
    },
    {
        path: '/tools/data/export',
        component: ExportToolData
    },
    {
        path: '/tools/data/import'
    },
    {
        path: '/tools/kits/list',
        component: ToolKits
    },
    {
        path: '/tools/kits/:id',
        component: CreateToolKit
    },
    {
        path: '/tools/kits/create',
        component: CreateToolKit
    },
    {
        path: '/tools/accessories/create',
        component: CreateAccessory
    },
    {
        path: '/tools/consumables/create',
        component: CreateConsumable,
    },
    {
        path: '/tools/accessories/:id',
        name: 'EditAccessory',
        component: CreateAccessory
    },
    {
        path: '/tools/consumables/:id',
        name: 'EditConsumable',
        component: CreateConsumable,
    },
    {
        path: '/test/subscriptions',
        component: CreateSubscriptionForm,
    },
    {
        path: '/notifications/subscriptions',
        component: NotificationSubscriptions,
    },
    {
        path: '/tools/certifications',
        name: 'tools-certifications',
        component: Certifications,
    },
    {
        path: '/tools/checkout/approval',
        name: 'tools-checkout-approval',
        component: ToolCheckoutApproval
    },
    {
        path: '/tools/checkout/request',
        name: 'tools-check-out',
        component: ToolCheckout
    },
    {
        path: '/tools/check-in',
        name: 'tools-check-in',
        component: ToolCheckIn,
    },
    {
        path: '/tools/categories',
        name: 'tools-categories',
        component: ToolCategories,
    },
    {
        path: '/tools/create',
        name: 'tools-create',
        component: CreateTool
    },
    {
        path:'/vehicles/create',
        name: 'vehicles-create',
        component: CreateVehicle
    },
    {
        path: '/vehicles/list',
        name: 'vehicles',
        component: ListVehicles
    },
    {
        path: '/vehicles/:id',
        name:'edit-vehicle',
        component: CreateVehicle
    },
    {
        path: '/tools/list',
        name: 'tools',
        component: Tools
    },
    {
        path: '/tools/:id',
        name: 'EditTool',
        component: CreateTool
    },
    {
        path: '/reports/tools',
        component: ToolReport
    },
    {
        path: '/reports/photos',
        component: PhotoReport
    },
    {
        path: "/v1/transmit-form",
        component: TransmitForm,
    },
    {
        path: "/v1/receive-form",
        component: ReceiveForm
    },
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            requiresAuth: false
        },
        component: Login
    },
    {
        path: '/styles',
        name: 'Styles',
        component: Styles,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    },
    {
        path: "/receive-form",
        name: "Receive Form",
        component: ReceiveFormV2,
        beforeEnter: (to, from, next) => {
            next({path: "/requests/receiving"});
        }
    },
    {
        path: '/approval/requests/:tab?',
        name: 'Approval Requests',
        component: ApprovalRequests
    },
    {
        path: "/requests/transmittal",
        name: "New Transmittal Request Form",
        beforeEnter: (to, from, next) => {
            Axios.get('/v2/forms/requests/transmittal/new').then(response => {
                let uuid = get(response, 'data.uuid');
                if(uuid) {
                    next({path: "/requests/transmittal/" + uuid});
                }
            })
                .catch(() => {
                    next({path: '/'});
                });
        }
    },
    {
        path: '/requests/transmittal/approve/:uuid',
        name: 'Approve Transmittal Request',
        component: ApproveTransmittalRequest
    },
    {
        path: "/requests/transmittal/:uuid",
        name: "Transmittal Request Form",
        component: TransmittalRequestForm,
    },
    {
        path: "/requests/receiving",
        name: "New Receiving Request Form",
        beforeEnter: (to, from, next) => {
            Axios.get('/v2/forms/requests/receiving/new').then(response => {
                let uuid = get(response, 'data.uuid');
                if(uuid) {
                    next({path: "/requests/receiving/" + uuid});
                }
            })
                .catch(() => {
                        next({path: '/'});
                });
        }
    },
    {
        path: '/requests/receiving/approve/:uuid',
        name: 'Approve Receiving Request',
        component: ApproveReceivingRequestForm
    },
    {
        path: "/requests/receiving/:uuid",
        name: "Receiving Request Form",
        component: ReceivingRequest,
    },
    {
        path: "/requests/rma",
        name: "New RMA Request Form",
        beforeEnter: (to, from, next) => {
            Axios.get('/v2/forms/requests/rma/new').then(response => {
                let uuid = get(response, 'data.uuid');
                if(uuid) {
                    next({path: "/requests/rma/" + uuid});
                }
            }).catch(() => {
                next({path: '/'});
            });
        }
    },
    {
        path: "/requests/rma/:uuid",
        name: "RMA Request Form",
        component: RmaRequestForm,
    },
    {
        path: "/requests/rma/approve/:uuid",
        name: "Approve RMA Request Form",
        component: ApproveRmaRequest,
    },
    {
        path: "/transmit-form",
        name: "Transmittal Form",
        component: TransmitFormV2,
        beforeEnter: (to, from, next) => {
            next({path: "/requests/transmittal"});
        }
    },
    {
        path: "/inventory-form",
        name: "Inventory Form",
        component: InventoryForm,
    },
    {
        path: "/po-form",
        name: "Purchase Order Lookup and Import Form",
        component: PurchaseOrderForm
    },
    {
        path: "/restock-form",
        name: "Return to Warehouse from Job Restock Form",
        component: RestockForm,
    },
    {
        path: "/receive-owner-materials-form",
        name: "Receive Owner Provided Materials Form",
        component: ReceiveOwnerMaterialsForm,
    },
    {
        path: "/kit-form",
        name: "Project Kit Creation Form",
        component: KitForm,
    },
    {
        path: "/rma-form",
        name: "RMA Form",
        component: RmaForm,
        beforeEnter: (to, from, next) => {
            next({path: "/requests/rma"});
        }
    },
    {
        path: "/warehouse-inventory",
        name: "Warehouse Inventory",
        component: WarehouseInventory,
    },
    {
        path: "/test-dymo",
        name: "Test DYMO label and Printing",
        component: TestDymo,
    },
    {
        path: '/test-locations',
        name: 'Test Locations',
        component: TestLocations
    },
    {
        path: "*",
        name: "404",
        component: PageNotFound
    }
];

const router = new VueRouter({
    routes,
});

export default router;